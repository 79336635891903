<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper">
        <!-- <div style="margin-bottom: 20px">
          <span>2022-01-20 以前的退款订单记录请</span>
          <span @click="goquery()" class="goquery">点此查询</span>
        </div> -->
        <!--（表单）选项区域-->
        <el-form
          ref="form"
          :model="formInline"
          :inline="true"
          label-position="right"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
                <el-autocomplete
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsyncOrder"
                  placeholder="车牌号"
                  :maxlength="8"
                  :trigger-on-focus="false"
                  @select="handleSelectOrder"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="退款订单号:">
                <el-input
                  v-model="formInline.refundOrderId"
                  :onkeyup="
                    (formInline.refundOrderId = formInline.refundOrderId.replace(/[^\d]/g, ''))
                  "
                  placeholder="请输入"
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <el-form-item label="业务订单类型:">
                <el-select v-model="formInline.businessOrderType" placeholder="请选择">
                  <el-option label="全部" value></el-option>
                  <el-option
                    v-for="item in refundTypeList"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务订单ID:">
                <el-input
                  v-model="formInline.businessRecordId"
                  :onkeyup="
                    (formInline.businessRecordId = formInline.businessRecordId.replace(
                      /[^\d]/g,
                      ''
                    ))
                  "
                  placeholder="请输入"
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <el-form-item label="支付订单ID:">
                <el-input
                  v-model="formInline.businessOrderId"
                  :onkeyup="
                    (formInline.businessOrderId = formInline.businessOrderId.replace(/[^\d]/g, ''))
                  "
                  placeholder="请输入"
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <el-form-item label="退款状态:">
                <el-select v-model="formInline.refundPaymentState" placeholder="请选择">
                  <!-- <el-option label="全部" value=""></el-option> -->
                  <el-option
                    v-for="item in statusList"
                    :key="item.code"
                    :label="item.desc || '全部'"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="退款原因:">
                <el-select v-model="formInline.flatAccountDealType" placeholder="请选择">
                  <el-option label="全部" value></el-option>
                  <el-option
                    v-for="item in orderAppealTypeList"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!--第二行-->
              <el-form-item label="渠道流水号:">
                <el-input
                  v-model="formInline.tradeNo"
                  placeholder="请输入"
                  maxlength="29"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="原生渠道流水号:">
            <el-input v-model="formInline.tradeNo" placeholder="请输入" maxlength="29"></el-input>
          </el-form-item> -->
              <el-form-item label="退款申请时间:">
                <timeRangePick
                  @timeChange="timeChangeSQ"
                  ref="timeRangePickerFQ"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile style="display: inline-block" :exportData="exportData"></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表-->
      <div class="tableWrapper paddingB20">
        <!-- <div class="paddingB10" style="float:right">
          <span v-if="!isRetry">自动重试: </span><span v-if="!isRetry" style="color:red;">已关闭</span>
          <span v-if="isRetry">距离下次重试: </span><span v-if="isRetry" style="color:green;">23分48秒</span>
          <el-switch v-model="isRetry"> </el-switch>
        </div> -->
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
          >
            <template slot-scope="scope">
              <span
                v-if="item.prop == 'refundOrderId'"
                v-clipboard:copy="scope.row.refundOrderId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.refundOrderId }}</p>
              </span>
              <span
                v-else-if="item.prop == 'businessRecordId'"
                v-clipboard:copy="scope.row.businessRecordId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.businessRecordId }}</p>
              </span>
              <span
                v-else-if="item.prop == 'businessOrderId'"
                v-clipboard:copy="scope.row.businessOrderId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.businessOrderId }}</p>
              </span>
              <span v-else-if="item.prop == 'plateNumber'" class="">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.plateNumber"
                  placement="right"
                >
                  <span>{{ scope.row.plateNumber }}</span>
                </el-tooltip>
              </span>
              <span
                v-else-if="item.prop == 'payOrderId'"
                v-clipboard:copy="scope.row.payOrderId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.payOrderId }}</p>
              </span>
              <span v-else>
                <p>
                  {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
                </p>
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('searchModule.state')" fixed="right" width>
            <template slot-scope="scope">
              <p>{{ scope.row.refundPaymentStateName }}</p>
              <p v-if="retryOrderProcessRecordId === scope.row.refundOrderId">
                重试中
                <span class="dotloading">...</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width="120">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="retry(scope)"
                v-if="scope.row.refundState == 1 || scope.row.refundState == 4"
                >重试</el-button
              >
              <el-button type="text" @click="seeDetails(scope)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <refundDetailsDialog
        ref="mychild"
        :dialogVisibleDetails="dialogVisibleDetails"
        @consoleDetails="consoleDetails"
        :refundFlowId="refundOrderId"
      ></refundDetailsDialog>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import refundDetailsDialog from "./componets/refundDetailsDialog";
import { setIndex, dateFormat } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";
export default {
  name: "examine",
  data() {
    return {
      exportData: {},
      dialogVisibleDetails: false,
      defalutDate: [],
      pageNum: 1,
      total: 0,
      pageSize: 15,
      loading: false,
      tableData: [],
      statusList: [
        {
          code: "",
          desc: "全部",
        },
        {
          code: 1,
          desc: "待确认",
        },
        {
          code: 2,
          desc: "退款中",
        },
        {
          code: 3,
          desc: "已完成",
        },
        {
          code: 4,
          desc: "退款失败",
        },
      ],
      orderAppealTypeList: [
        {
          code: 11,
          desc: "异常订单处理",
        },
        {
          code: 12,
          desc: "自动平单退款",
        },
        {
          code: 2,
          desc: "重复支付退款",
        },
        {
          code: 7,
          desc: "异常支付退款",
        },
      ],
      refundTypeList: [],
      isRetry: false, // 是否重试
      formInline: {
        refundOrderId: "",
        businessOrderType: "",
        businessOrderId: "",
        payOrderId: "",
        refundPaymentState: "",
        flatAccountDealType: "",
        startDate: "",
        endDate: "",
        businessRecordId: "",
        tradeNo: "",
        name: `退款订单查询`,
        plateNumber: "",
        carId: "",
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "lastedTryRefundTimeStr",
          label: "最近尝试时间",
        },
        {
          prop: "channelTypeName",
          label: this.$t("list.Payment_channels"),
        },
        {
          prop: "needRefundActualMoney",
          label: "应退实付(元)",
          formatter: (row, column) => {
            if (row.needRefundActualMoney) {
              return Number(row.needRefundActualMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "needRefundAgioMoney",
          label: "应退优惠(元)",
          formatter: (row, column) => {
            if (row.needRefundAgioMoney) {
              return Number(row.needRefundAgioMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "refundReasonStr",
          label: this.$t("list.refund_reason"),
        },
        {
          prop: "applyDateStr",
          label: "退款申请时间",
        },
        {
          prop: "refundOrderId",
          label: "退款订单ID",
        },
        {
          prop: "businessOrderTypeName",
          label: "业务订单类型",
        },
        {
          prop: "businessRecordId",
          label: "业务订单ID",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
        },
        {
          prop: "paymentTypeName",
          label: "支付订单类型",
        },
        {
          prop: "businessOrderId",
          label: "支付订单ID",
        },
        {
          prop: "tradeNo",
          label: "渠道流水号",
        },
        // {
        //   prop: "tradeNo",
        //   label: "原生渠道流水号",
        // },
      ],
      retryOrderProcessRecordId: "", // 重试中的ID
      refundOrderId: "",
    };
  },
  watch: {
    "formInline.plateNumber"(newVal) {
      if (!newVal) {
        this.handleSelectOrder();
      }
    },
  },
  methods: {
    goquery() {
      this.$router.push({
        path: "/record",
      });
    },
    // 退款状态
    // getStatusList() {
    //   let opt = {
    //     url: "/acb/2.0/orderAppeal/appealDealStatusEnum",
    //     method: "get",
    //     data: {},
    //     success: res => {
    //       if (res.state == 0) {
    //         this.statusList = res.value;
    //       } else {
    //         this.$alert("获取退款状态枚举值失败", this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine')
    //         });
    //       }
    //     }
    //   };
    //   this.$request(opt);
    // },
    // 退款原因
    // getOrderAppealTypeList() {
    //   let opt = {
    //     url: "/acb/2.0/orderAppeal/appealTypeEnum",
    //     method: "get",
    //     data: {},
    //     success: res => {
    //       if (res.state == 0) {
    //         this.orderAppealTypeList = res.value;
    //       } else {
    //         this.$alert("获取退款原因枚举值失败", this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine')
    //         });
    //       }
    //     }
    //   };
    //   this.$request(opt);
    // },
    // 业务订单类型枚举值
    getBusinessOrderTypeList() {
      let opt = {
        url: "/acb/2.0/orderAppeal/allBusinessOrderTypeEnum",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.refundTypeList = res.value;
          } else {
            this.$alert("获取业务订单类型枚举值失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    resetForm() {
      this.formInline = {
        refundOrderId: "",
        businessOrderType: "",
        businessOrderId: "",
        businessRecordId: "",
        payOrderId: "",
        refundPaymentState: "",
        flatAccountDealType: "",
        startDate: "",
        endDate: "",
        name: `退款订单查询`,
        plateNumber: "",
      };
      this.$refs.timeRangePickerFQ.resetTime();
      this.exportData = {
        url: "/acb/2.0/refundOrder/export",
        methods: "post",
        data: this.formInline,
      };
      this.pageNum = 1;
      this.searParkRecordList();
    },
    consoleDetails() {
      this.dialogVisibleDetails = false;
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChangeSQ(timeArr) {
      this.formInline.startDate = timeArr[0];
      this.formInline.endDate = timeArr[1];
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    seeDetails(scope) {
      console.log("scope", scope.row.businessOrderType);
      this.refundOrderId = scope.row.refundOrderId;
      this.dialogVisibleDetails = true;
      this.$refs.mychild.searParkRecordListFun(
        scope.row.refundOrderId,
        scope.row.businessOrderType
      );
    },

    handleSelectOrder(item) {
      this.loading = false;
      if (item) {
        this.formInline.plateNumber = item.plateNumber;
        this.formInline.carId = item.carId;
      } else {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
      }
    },
    querySearchAsyncOrder(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },

    // 搜索
    searParkRecordList() {
      let flagFQ = this.$route.params.tradeNo
        ? true
        : this.showLog("退款申请时间：", this.formInline.startDate, this.formInline.endDate);
      if (flagFQ) {
        this.searParkRecordListFun();
      }
    },
    showLog(name, start, end) {
      if (start && end) {
        let time = new Date(end) - new Date(start);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert(name + "查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    searParkRecordListFun() {
      let opt = {
        method: "get",
        // url: "/acb/2.0/refundFlow/list",
        url: "/acb/2.0/refundOrder/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
        },
        success: (res) => {
          this.loading = false;
          this.retryOrderProcessRecordId = ""; // 清除重试ID
          if (res.state == 0) {
            // this.tableData = setIndex(this.pageNum, res.value.list);
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 重试
    retry(scope) {
      this.retryOrderProcessRecordId = scope.row.refundOrderId;
      let opt = {
        method: "post",
        url: "/acb/2.0/refundOrder/refundRetry",
        data: {
          refundOrderId: scope.row.refundOrderId,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            console.log("重试成功");
            this.searParkRecordList();
          } else {
            this.searParkRecordList();
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (res) => {
          this.searParkRecordList();
        },
      };
      this.$request(opt);
    },
  },
  created() {
    // this.getStatusList();
    this.getBusinessOrderTypeList();
    // console.log("route", this.$route.params.tradeNo);
  },
  mounted() {
    if (this.$route.params.tradeNo) {
      console.log("123");
      this.formInline.tradeNo = this.$route.params.tradeNo;
      this.$refs.timeRangePickerFQ.resetTime2();
      this.formInline.startDate = "";
      this.formInline.endDate = "";
    } else {
      this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
    }
    this.searParkRecordList();
    // 导出数据
    this.exportData = {
      url: "/acb/2.0/refundOrder/export",
      methods: "post",
      data: this.formInline,
    };
  },
  components: {
    exportFile,
    timeRangePick,
    refundDetailsDialog,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">


.tableWrapper {
  background: #fff;
}

.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}

.title {
  margin-top: 30px;
}

.warning {
  color: red;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.titleh1 {
  margin: 10px 10px;
  font-size: 15px;
}

.but {
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.orderNumberStyle {
  color: #0f6eff;
}

.goquery {
  color: blue;
  cursor: pointer;
}
</style>
